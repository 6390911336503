<template>
    <div class="cardSuccessView" v-loading="goGetClothesLoading" element-loading-text="前往收衣中,请稍等...">
        <div class="leftView">
            <div>
                <el-page-header @back="goBack" content="办卡/充值成功">
                </el-page-header>
            </div>
            <div class="leftView-card">
                <img src="../../assets/img/yearCard.png"/>
                <h3>{{ applyCardInfo.cardName }}</h3>
            </div>
            <div class="leftView-form">
                <el-form ref="form" label-width="80px">
                    <el-form-item label="手机号">
                        <el-input disabled v-model="applyCardInfo.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="卡号">
                        <el-input v-model="applyCardInfo.cardNo" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input v-model="applyCardInfo.realName" disabled></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="rightView">
            <div class="goToCollectClothes">
                <h3>账户信息</h3>
                <el-button type="primary" @click="_handleConfirmKey(applyCardInfo.phone)">前往收衣</el-button>
            </div>
            <div class="rightView-form">
                <el-form label-width="100px">
                    <el-form-item label="通享卡余额">
                        <el-input v-model="applyCardInfo.universalBalance" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="本店历史余额">
                        <el-input v-model="applyCardInfo.storeHistoryBalance" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="办卡时间">
                        <el-input v-model="applyCardInfo.applyCardTime" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="到期时间">
                        <el-input v-model="applyCardInfo.expireDate" disabled></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="rightView-btn">
                <div>
                    <el-button @click="printPaper" v-if="applyCardInfo.cardType == 1">打印办卡信息</el-button>
                    <el-button @click="chargerPrintPaper" v-if="applyCardInfo.cardType == 3">打印充值信息</el-button>
                    <!-- 携带某个参数，打开充值页面 -->
                    <el-button v-if="applyCardInfo.cardActivityType === 2" type="primary"
                               @click="goToRecharge">前往充值
                    </el-button>
                </div>
            </div>
        </div>
        <transition name="payFullscreen">
            <chargerPayView v-if="q" :pay-object="payObject" @handleCloseCardPay="handleCloseCardPay"></chargerPayView>
        </transition>
    </div>

</template>

<script>
import {getApplyCardInfo} from '@/api/member/card'
import {getLodop} from '@/print/LodopFuncs'
import ChargerPayView from '@/view/charger/components/chargerPayView'
import {getUserInfo} from "@/api/store/user";

export default {
    name: "applyCardSuccess",
    components: {
        ChargerPayView,
    },
    data() {
        return {
            applyCardInfo: {
                cardActivityType: 0,
                cardType: 1
            },
            q: false,
            payObject: {},
            goGetClothesLoading: false
        }
    },
    watch: {
        $route(to, from) {
            this.$router.go(0)
        }
    },
    created() {
        this.q = false
        // 获取办卡完成的参数
        if (this.$route.query.userRecordId) {
            let userRecordId = this.$route.query.userRecordId;
            this.queryApplyCardInfo(userRecordId);
        }
    },
    methods: {
        // 查询数据
        async queryApplyCardInfo(userRecordId) {
            let res = await getApplyCardInfo(userRecordId);
            this.applyCardInfo = res.data.data
        },
        goToRecharge() {
            // 用户Id
            // 会员卡Id
            this.payObject = {
                phone: this.applyCardInfo.phone,
                cardId: this.applyCardInfo.cardId,
            }
            this.q = true
        },
        handleCloseCardPay() {
            this.q = false
        },
        // 办卡打印
        printPaper() {
            console.log("打印:" + this.result);
            let LODOP = getLodop();

            LODOP.SET_LICENSES("", "660FDFD7D9F7F7101A272F2EB57C306A", "C94CEE276DB2187AE6B65D56B3FC2848", "");

            LODOP.PRINT_INIT("收银小票");               //首先一个初始化语句
            LODOP.SET_PRINT_PAGESIZE(3, "75mm", "20mm", "收银小票");
            let height = 0;
            //门店信息
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", this.applyCardInfo.storeName + "办卡");
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            //门店电话
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "门店电话：" + this.applyCardInfo.storePhone);
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            //门店地址
            height = height + 4;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "地址：" + this.applyCardInfo.address);
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

            //线
            height = height + 6;
            LODOP.ADD_PRINT_LINE(height + "mm", 0, "16mm", "75mm", 3, 0);
            //办卡信息
            // height = height + 6;
            // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员卡号：" + this.applyCardForm.cardNo === '' ? this.applyCardForm.phone : this.applyCardForm.cardNo);
            // LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员姓名：" + this.applyCardInfo.realName);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员电话：" + this.applyCardInfo.phone);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员卡名：" + this.applyCardInfo.cardName);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "办卡时间：" + this.getTime());
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "办卡金额：" + this.applyCardInfo.cardPrice + "元");
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "付款方式：" + this.applyCardInfo.payWayStr);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "顾客签名：_________");
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

            LODOP.SET_PRINTER_INDEX(-1)
            LODOP.PRINT();
        },
        // 充值打印
        chargerPrintPaper() {
            console.log("打印:" + this.result);
            let LODOP = getLodop();
            LODOP.SET_LICENSES("", "660FDFD7D9F7F7101A272F2EB57C306A", "C94CEE276DB2187AE6B65D56B3FC2848", "");

            LODOP.PRINT_INIT("收银小票");               //首先一个初始化语句
            LODOP.SET_PRINT_PAGESIZE(3, "75mm", "20mm", "收银小票");

            let height = 0;

            //门店信息
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "洗衣通洗护生活馆");
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", this.applyCardInfo.storeName + "充值小票");
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 13);
            // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            //门店电话
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "门店电话：" + this.applyCardInfo.storePhone);
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            //门店地址
            height = height + 4;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "地址：" + this.applyCardInfo.address);
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

            //线
            height = height + 6;
            LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);
            //充值信息
            // height = height + 6;
            // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员卡号：" + this.result.cardNo === '' ? this.result.phone : this.rechargeForm.cardNo);
            // LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员姓名：" + this.applyCardInfo.realName);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员电话：" + this.applyCardInfo.phone);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "会员卡名：" + this.applyCardInfo.cardName);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "充值金额：" + this.applyCardInfo.cardPrice + "元");
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "卡内余额：" + this.applyCardInfo.totalBalance + "元");
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "现金券余额：" + this.applyCardInfo.cashCouponBalance + "元");
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "付款方式：" + this.applyCardInfo.payWayStr);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "操作时间：" + this.getTime());
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

            LODOP.SET_PRINTER_INDEX(-1)
            LODOP.PRINT();
        },
        getTime() {
            var date1 = new Date();
            var year = date1.getFullYear();
            var month = date1.getMonth() + 1;
            var day = date1.getDate();
            var hours = date1.getHours();
            var minutes = date1.getMinutes();
            var seconds = date1.getSeconds();
            return year + "年" + month + "月" + day + "日" + hours + ":" + minutes + ":" + seconds
        },
        goBack() {
            this.$router.push('/home/get')
        },
        _handleConfirmKey(phone) {
            this.goGetClothesLoading = true
            const params = new URLSearchParams()
            params.append('phoneOrCardNum', phone)
            getUserInfo(params).then(response => {
                this.goGetClothesLoading = false
                this.getLoading = false
                this.userInfo = response.data.data;
                console.log(this.userInfo);
                let data = {
                    userId: this.userInfo.userId,
                    phone: this.userInfo.phone,
                    name: this.userInfo.realName,
                    address: this.userInfo.address,
                    userType: this.userInfo.userType,
                    balance: this.userInfo.memberUser !== null ? this.userInfo.memberUser.balance : 0
                }
                let userInfo = JSON.stringify(data);
                this.$router.push({path: '/get/types', query: {'data': userInfo}})
            })
        },
    }
}
</script>

<style scoped lang="scss">
.cardSuccessView {
  display: flex;
  height: 100%;
  padding: 15px;

  .leftView {
    flex: 5;
    border-right: 1px solid #F4F4F5;

    .leftView-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;

      img {
        width: 350px;
        height: 200px;
      }
    }

    .leftView-form {
      width: 50%;
      margin: 20px auto 0;
    }
  }

  .rightView {
    flex: 5;
    padding-left: 30px;
    margin-top: 80px;

    .goToCollectClothes {
      display: flex;
      justify-content: space-between;
      width: 60%;

      h3 {
        margin: 0;
      }
    }

    .rightView-form {
      width: 60%;
    }

    .rightView-btn {
      > div {
        display: flex;
        justify-content: center;
        width: 60%;
      }
    }
  }
}

.el-form-item {
  margin-top: 30px;
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

//动画过程
.payFullscreen-enter {
  animation: payFullscreen-dialog-fade-in .9s ease;
}

.payFullscreen-leave {
  animation: payFullscreen-dialog-fade-out .9s ease forwards;
}

.payFullscreen-enter-active {
  animation: payFullscreen-dialog-fade-in .9s ease
}

.payFullscreen-leave-active {
  animation: payFullscreen-dialog-fade-out .9s ease forwards
}

@keyframes payFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes payFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}
</style>
