<template>
  <div class="cardCardCheckoutView" v-loading="otherPayLoading" element-loading-text="充值中...">
    <div class="leftView" style="margin-right: 480px;">
      <div class="topView">
        <label>支付</label>
<!--        <button class="btn-back" @click="childMethod"></button>-->
        <div class="btn-back iconfont icon-31fanhui1" @click="childMethod"></div>
      </div>
      <div class="contentView listView" style="height: 620px;">
        <div class="orderInfoView">
          <div class="timeView">充值时间：{{ $moment().format('YYYY-MM-DD HH:mm') }}</div>
          <div class="cardView">
            <label>卡片名称：{{ this.rechargeForm.cardName }}</label>
          </div>
          <div class="toPayView">
            <div class="amtItem subItem">
              <label class="label-name">
                <span>储值应收</span>
              </label>
              <label class="label-amt">
                <span>¥ {{ rechargeForm.payPrice }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="paymentView">
          <div class="tView">支付方式</div>
          <div class="listView">
            <div class="paymentItem listItem" v-for="(item,index) in pay_ways"
                 @click="handlePayWay(item)">
              <div class="iconView">
                <img :src="item.ico">
              </div>
              <div class="textView overflowText">{{ item.name }}</div>
              <div class="selectView" v-if="payWay === item.label">
                <img src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_Black.png">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="rightView" style="width: 480px;">
      <div class="defImgView">
        <img src="https://static.bokao2o.com/wisdomDesk/images/Def_Bc_OrderCheckout_Right.png">
        <p>{{ orderCheckoutVal }}</p>
      </div>
      <transition name="paidFullscreen">
        <div id="qrCodePayPopView" class="popView" style="width: 480px;"
             v-loading="qrCodePayView"
             :element-loading-text="qrCodePayViewName" v-if="payWayView">
          <div class="popView-bcView" @click="handleClosePayWay">
          </div>
          <div class="popView-contentView"
               style="width: 450px; top: 0; right: 0; overflow: auto;">
            <div class="qrCodePayView" style="height: 794px;">
              <div class="contentView" style="height: 513px;">
                <div class="amtView">
                  ¥ <span>{{ rechargeForm.payPrice }}</span>
                </div>
                <div class="imgView">
                  <img src="https://www.shouqianba.com/img/logo.svg">
                </div>
                <div class="qrCodeInputView">
                  <el-input class="input-scanPayCode" placeholder="请扫描或输入付款码"
                            autocomplete="off"
                            ref="pay"
                             v-model="payVal"
                      type="number"
                            autofocus="autofocus"></el-input>
                  <el-button type="primary" @click="recharge">收款</el-button>
                </div>
                <div class="tipView">
                  请使用支付宝/微信扫码支付
                  <p>支付过程中，请不要关闭该二维码！</p>
                </div>
              </div>
            </div>
            <button class="btn-close" @click="payWayView = false"></button>
          </div>

        </div>
      </transition>

    </div>

  </div>
</template>

<script>
import {payCardQuery, payOrder, recharge} from "@/api/member/user";
import {getMemberCardInfoByCardId} from "@/api/member/card";

export default {
  name: "chargerPayView",
  props: {
    payObject:{
      type: Object
    }
  },
  data() {
    return {
      orderCheckoutVal: '请选择结账方式',
      otherPayLoading: false,
      qrCodePayView: false,
      qrCodePayViewName: '收款中...',
      payWay: '',
      payWayView: false,
      // 支付方式
      pay_ways: [
        {
          name: '收钱吧',
          ico: 'https://www.shouqianba.com/img/logo.svg',
          label: 6,
        },
      ],
      rechargeForm: {
        userId: '',
        userType: '',
        phone: '',
        cardNo: '',
        realName: '',
        gender: '1',
        payPrice: 0,
        payCode: '',
        cardId: 0,
        cardName: '',
        channel: 1,
        payWay: 6,
        payWayName: '收钱吧',
        source: 1,
      },
      // 支付二维码
      payVal: '',
      myInterval: null,
      shouInterval: null,
      outTradeNo:'',
      userRecordId:''
    }
  },
  created() {
    this.rechargeForm.phone = this.payObject.phone
    this.rechargeForm.cardId = this.payObject.cardId
    // 需要查询会员卡信息
    this.getCardInfo(this.payObject.cardId);
  },
  methods: {
    // 查询会员卡信息
    async getCardInfo(cardId) {
      let res = await getMemberCardInfoByCardId(cardId);
      console.log(res);
      this.rechargeForm.cardName = res.data.data.cardName
      this.rechargeForm.payPrice = res.data.data.cardPrice

    },
    // 关闭会员卡支付页面
    handleCloseCardPay() {
      this.q = false
      this.payWay = ''
      this.payWayView = false
    },
    // 处理点击支付
    handlePayWay(item) {
      this.payWayView = false
      this.payWay = item.label
      this.rechargeForm.payWay = this.payWay
      this.rechargeForm.payWayName = item.name
      if (item.label === 6) {
        this.payWayView = true
        this.$nextTick(() => {
          this.$refs.pay.focus()
        })
      }
      if (item.label === 4) {
        this.orderCheckoutVal = '请使用' + item.name + '收款: ' + this.rechargeForm.payPrice
      }
    },
    recharge() {
      // this.$router.push({name: 'applyCardSuccess', params: {data: JSON.stringify(this.result)}})
      if (!this.payWay) {
        this.$message.error('请选择支付方式');
        return false
      }
      if (this.payWay !== 4 && !this.payVal) {
        this.$message.error('请输入付款码');
        return false;
      }
      if (this.payWay === 4){
        this.otherPayLoading = true;
      } else {
        this.qrCodePayView = true;
      }
      this.rechargeForm.payCode = this.payVal
      console.log(this.rechargeForm);
      recharge(this.rechargeForm).then(response => {
        console.log(response);
        if (response.data.code === 0) {
          this.result = response.data.data;
          if (response.data.data) {
            this.outTradeNo = response.data.data.outTradeNo
            this.userRecordId = response.data.data.userRecordId
            // 调用支付接口
            this.payOrder()
          }
        } else {
          this.qrCodePayView = false;
          this.otherPayLoading = false;
          //支付失败
          this.$message.error(response.data.msg);
        }
      })
    },
    payOrder() {
      let payWay = this.rechargeForm.payWay;
      let userRecordId = this.userRecordId;
      let authCode = this.rechargeForm.payCode;
      let data = {
        payWay,
        userRecordId,
        authCode
      }
      payOrder(data).then(response => {
        console.log(response);
        // this.isPrint = false;
        if (response.data.code === 0) {
            // 只有微信 支付宝有回调
            if (payWay === 1 || payWay === 2 || payWay === 6) {
              this.outTradeNo = response.data.data.outTradeNo
              this.queryPayResult(this.outTradeNo) //调用接口的方法
              this.myInterval = setInterval(() => {
                this.queryPayResult(this.outTradeNo) //调用接口的方法
              }, 3000);
            } else {
              this.commonPaySuccess()
            }
          } else {
          if (payWay === 1 || payWay === 2 || payWay === 6) {
            clearInterval(this.shouInterval)
            // 需要用户支付
            if (response.data.data.errCode === 'USERPAYING') {
              console.log(response);
              this.outTradeNo = response.data.data.outTradeNo
              this.myInterval = setInterval(() => {
                this.queryPayResult(this.outTradeNo) //调用接口的方法
              }, 3000);
            } else {
              console.log(response);
              this.qrCodePayView = false
              this.$message.error(response.data.data.msg);
              this.closePayModal()
            }
          } else {
            this.qrCodePayView = false;
            this.otherPayLoading = false;
            this.$message.error(response.data.data.msg);
          }

        }
      }).catch(reason => {
        // 要进行跳转到当前未支付的订单
        console.log(reason);
        this.closePayModal()
      })
      if (this.rechargeForm.payWay === 6) {
        this.qrCodePayViewName = '等待用户输入密码...'
      }
    },
    // 查询支付结果
    queryPayResult() {
      const params = new URLSearchParams()
      params.append('payWay', this.rechargeForm.payWay)
      params.append('outTradeNo', this.outTradeNo)
      payCardQuery(params).then(response => {
        console.log(response);
        // 微信
        if (response.data.data.success) {
          if (response.data.data.tradeState === 'SUCCESS') {
            clearInterval(this.shouInterval)
            this.commonPaySuccess();

          } else if (response.data.data.tradeState === 'USERPAYING') {
            console.log(response.data.data.tradeState);
            this.qrCodePayViewName = '等待用户输入密码'
          } else {
            this.qrCodePayView = false
            this.$message.error(response.data.data.msg);
            this.rechargeForm.payCode = ''
            clearInterval(this.myInterval)
          }
        } else {
          this.$message.error(response.data.data.msg);
          this.qrCodePayView = false
          clearInterval(this.myInterval)
        }
      })
    },
    // 公共的
    commonPaySuccess() {
      this.qrCodePayView = false;
      this.otherPayLoading = false;
      this.$message({
        message: '充值成功!',
        type: 'success'
      });
      clearInterval(this.myInterval)
      this.$router.push({
        name: 'applyCardSuccess',
        query: {
          userRecordId:  this.userRecordId
        }
      })
    },
    // 关闭支付方式
    handleClosePayWay() {
      this.payWayView = false
    },
    childMethod() {
      this.$emit('handleCloseCardPay')
    },
    closePayModal() {
      this.qrCodePayView = false
      clearInterval(this.myInterval);
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
::v-deep input[type="number"]{
    -moz-appearance: textfield;
}

.cardCardCheckoutView {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  padding-left: 100px;
  z-index: 100;

  > .leftView {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;

    > .topView {
      position: relative;
      background: #fff;
      padding: 25px 0 15px 0;
      text-align: center;

      > label {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #333;
        line-height: 40px;
      }

      > .btn-back {
        position: absolute;
        top: 25px;
        left: 15px;
        width: 40px;
        height: 40px;
        background-size: 28px;
        border: none;
        outline: none;
      }
    }

    > .contentView {
      overflow-x: hidden;
      overflow-y: auto;

      > .orderInfoView .subItem {
        display: flex;
        padding: 12px 25px 12px 20px;
        line-height: 20px;

        > label {
          flex: 1;
          height: 20px;
        }

        > .label-name {
          flex: 2;
          color: #666;

          > span {
            font-family: PingFangSC-Semibold;
            font-size: 15px;
            color: #333;
            margin-right: 15px;
          }
        }

        > .label-amt {
          text-align: right;
          font-size: 15px;
          color: #333;
          font-family: DINAlternate-Bold;
        }
      }

      > .orderInfoView {
        padding: 0 30px 15px 30px;

        > .timeView {
          line-height: 30px;
          font-size: 14px;
          color: #666;
        }

        > .cardView {
          line-height: 32px;
          color: #333;
          font-size: 14px;

          > label {
            margin-right: 35px;
          }

          > label:last-child {
            margin-right: 0;
          }
        }

        > .toPayView {
          padding: 5px 0;
          margin-top: 15px;
          background: #f8f8f8;
          border-radius: 6px;
          overflow: hidden;
        }
      }

      > .paymentView {
        padding: 0 24px 0 24px;

        > .tView {
          margin: 0 6px;
          line-height: 40px;
          font-size: 15px;
          font-family: PingFangSC-Semibold;
          color: #333;
          border-top: 0.5px solid #ddd;
        }

        > .listView {
          overflow: hidden;

          > .paymentItem {
            position: relative;
            float: left;
            width: 87px;
            height: 87px;
            border-radius: 6px;
            background: #f8f8f8;
            padding: 10px 0;
            text-align: center;
            margin: 0 6px 10px 6px;
            overflow: hidden;

            > .iconView {
              height: 40px;

              > img {
                width: 40px;
                height: 40px;
              }
            }

            > .textView {
              margin-top: 8px;
              line-height: 20px;
              font-size: 14px;
              color: #333;
            }

            > .selectView {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(40, 40, 45, 0.4);
              text-align: center;
              border-radius: 6px;

              > img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }

    > .btnView {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding: 20px 0;
      text-align: center;
      background: #fff;

      > .btn-settle {
        display: inline-block;
        width: 260px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        background: #3370ff;
        border-radius: 6px;
      }
    }
  }

  > .rightView {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff url(https://static.bokao2o.com/wisdomDesk/images/Def_OrderCheckout_RightBorder.png) left center / 27px no-repeat;
    z-index: 10;

    > .defImgView {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      height: 180px;

      > img {
        width: 192px;
        height: 150px;
      }

      > p {
        margin-top: 10px;
        color: #666;
        line-height: 20px;
      }
    }
  }
}

#qrCodePayPopView {
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    background: transparent;
  }

  > .popView-contentView {
    border-radius: 0;
    box-shadow: none;
  }

  > .popView-contentView .qrCodePayView {
    position: relative;
    background: #fff;
    padding: 0 60px 0 30px;

    > .contentView {
      position: absolute;
      left: 30px;
      right: 60px;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      > .amtView {
        line-height: 50px;
        font-size: 32px;
        color: #FF5E56;

        > span {
          font-size: 36px;
          margin-left: 5px;
        }
      }

      > .imgView {
        margin-top: 20px;
        height: 46px;

        > img {
          width: 120px;
          height: 46px;
        }
      }

      > .qrCodeInputView {
        display: flex;
        padding: 30px 20px 20px 20px;

        > .input-scanPayCode {
          flex: 1;
          margin-right: 5px;
        }
      }

      > .tipView {
        line-height: 20px;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
  }
}

.popView {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  > .popView-contentView {
    position: absolute;
    overflow: auto;
    min-height: 200px;
    background: #fff;
    border-radius: 8px;

    > .btn-close {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 40px;
      height: 40px;
      background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png) center / 28px 28px no-repeat;
      border: none;
      outline: none;
    }
  }
}
</style>
